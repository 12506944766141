// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikeln-js": () => import("./../../../src/pages/artikeln.js" /* webpackChunkName: "component---src-pages-artikeln-js" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontact-js": () => import("./../../../src/pages/kontact.js" /* webpackChunkName: "component---src-pages-kontact-js" */),
  "component---src-pages-krankheitsbilder-js": () => import("./../../../src/pages/krankheitsbilder.js" /* webpackChunkName: "component---src-pages-krankheitsbilder-js" */),
  "component---src-pages-methoden-js": () => import("./../../../src/pages/methoden.js" /* webpackChunkName: "component---src-pages-methoden-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-studien-js": () => import("./../../../src/pages/studien.js" /* webpackChunkName: "component---src-pages-studien-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

